import { USER_LOCAL_STORAGE_KEYS } from "@common/constants/storage_keys"
import { User, UserAuthToken, UserId } from "@common/types/user"
import { PRIVATE_ROUTES } from "@common/constants"
import {
	getFromLocalStorage,
	isBrowser,
	removeFromLocalStorage,
	setToLocalStorage,
} from "../browser"
import { invariant, isUndefinedOrNull } from "../variable"

export function isLoginModalClosable(isClosable: boolean, pathname: string) {
	return isClosable && !PRIVATE_ROUTES.includes(pathname)
}

export const getUserAuthToken = (): string | undefined => {
	if (!isBrowser()) {
		return undefined
	}

	return getFromLocalStorage(USER_LOCAL_STORAGE_KEYS.AUTH_TOKEN)
}

export const storeAuthToken = (authToken: string): void => {
	if (isBrowser()) {
		setToLocalStorage(USER_LOCAL_STORAGE_KEYS.AUTH_TOKEN, authToken)
	}
}

export const getUserId = (): number | null | undefined => {
	if (!isBrowser()) {
		return undefined
	}

	return getFromLocalStorage<number | null | undefined>(
		USER_LOCAL_STORAGE_KEYS.USER_ID,
	)
}

export const storeUserId = (userId: number | null | undefined): void => {
	if (isBrowser()) {
		setToLocalStorage(USER_LOCAL_STORAGE_KEYS.USER_ID, userId)
	}
}

export const getUserName = (): string | undefined => {
	if (!isBrowser()) {
		return undefined
	}

	return getFromLocalStorage<string>(USER_LOCAL_STORAGE_KEYS.USER_NAME)
}

export const getUserPhoneNumber = (): string | undefined => {
	if (!isBrowser()) {
		return undefined
	}

	return getFromLocalStorage<any>(USER_LOCAL_STORAGE_KEYS.USER_PHONE_NUMBER)
}

export const storeUserName = (userName: string): void => {
	if (isBrowser()) {
		setToLocalStorage(USER_LOCAL_STORAGE_KEYS.USER_NAME, userName)
	}
}

export const getUserEmail = (): string | undefined => {
	if (!isBrowser()) {
		return undefined
	}

	return getFromLocalStorage(USER_LOCAL_STORAGE_KEYS.USER_EMAIL)
}

export const storeUserEmail = (userEmail: string): void => {
	if (isBrowser()) {
		setToLocalStorage(USER_LOCAL_STORAGE_KEYS.USER_EMAIL, userEmail)
	}
}

export const getAffiliateCode = () => {
	if (!isBrowser()) {
		return undefined
	}
	return getFromLocalStorage(USER_LOCAL_STORAGE_KEYS.AFFILIATE_CODE)
}

export const getReferralCode = () => {
	if (!isBrowser()) {
		return undefined
	}
	return getFromLocalStorage(USER_LOCAL_STORAGE_KEYS.REFERRAL_CODE)
}

export const getWhatsappOptIn = (): boolean | undefined => {
	if (!isBrowser()) {
		return undefined
	}

	return getFromLocalStorage(USER_LOCAL_STORAGE_KEYS.OPT_INS.WHATSAPP)
}

export const storeWhatsappOptIn = (whatsappOptIn: boolean): void => {
	if (isBrowser()) {
		setToLocalStorage(USER_LOCAL_STORAGE_KEYS.OPT_INS.WHATSAPP, whatsappOptIn)
	}
}

export const getIsUserBondsInvestor = (): boolean | undefined => {
	if (!isBrowser()) {
		return undefined
	}

	return getFromLocalStorage(USER_LOCAL_STORAGE_KEYS.BONDS_INVESTOR_STATUS)
}

export const getIsUserSgbInvestor = (): boolean | undefined => {
	if (!isBrowser()) {
		return undefined
	}

	return getFromLocalStorage(USER_LOCAL_STORAGE_KEYS.IS_SGB_INVESTOR)
}

export const getIsUserFDInvestor = (): boolean | undefined => {
	if (!isBrowser()) {
		return undefined
	}

	return getFromLocalStorage(USER_LOCAL_STORAGE_KEYS.FD_INVESTOR_STATUS)
}

export const getIsUserFDWaitlister = (): boolean | undefined => {
	if (!isBrowser()) {
		return undefined
	}

	return getFromLocalStorage(USER_LOCAL_STORAGE_KEYS.IS_FD_WAITLISTER)
}

export const isUserLoggedIn = (): boolean => {
	return !!getUserAuthToken()
}

export const getUserKycStatus = (): string | null | undefined => {
	if (!isBrowser()) {
		return undefined
	}

	return getFromLocalStorage(USER_LOCAL_STORAGE_KEYS.KYC.KYC_STATUS)
}

export const getUserSebiKycStatus = (): string | null | undefined => {
	if (!isBrowser()) {
		return undefined
	}

	return getFromLocalStorage(USER_LOCAL_STORAGE_KEYS.KYC.SEBI_KYC_STATUS)
}

export const defaultUserState: User = {
	authToken: undefined,
	userId: undefined,
	userName: undefined,
	userEmail: undefined,
	kycStatus: undefined,
	kycStatusV1: undefined,
	kycStatusV2: undefined,
	isExistingInvestor: undefined,
	isBondsInvestor: undefined,
	isSgbInvestor: undefined,
	isFDInvestor: undefined,
	isUserFDWaitlister: undefined,
	userPhoneNumber: undefined,
	cohorts: {
		signup: undefined,
		landingV3: undefined,
	},
	isLoggedIn: !!getUserAuthToken(),
	sebiNomineeStatus: undefined,
	marketData: {
		isMarketClosed: undefined,
		marketCloseReason: undefined,
		isWintBondMarketClosed: undefined,
		wintBondMarketCloseReason: undefined,
	},
	referralData: {
		gotReferred: false,
		successfulReferrals: 0,
		referralCode: "",
	},
	userDebarmentDetails: {
		isUserDebarred: false,
		startDate: 0,
		endDate: 0,
	},
}

export const getUser = (): User => {
	return {
		authToken: getUserAuthToken(),
		userId: getUserId(),
		userName: getUserName(),
		userEmail: getUserEmail(),
		kycStatus: getUserKycStatus(),
		kycStatusV1: getUserKycStatus(),
		kycStatusV2: getUserSebiKycStatus(),
		isExistingInvestor: getFromLocalStorage<boolean>(
			USER_LOCAL_STORAGE_KEYS.IS_EXISTING_INVESTOR,
		),
		isSgbInvestor: getIsUserSgbInvestor(),
		isBondsInvestor: getIsUserBondsInvestor(),
		isFDInvestor: getIsUserFDInvestor(),
		isUserFDWaitlister: getIsUserFDWaitlister(),
		cohorts: {
			signup: getFromLocalStorage(USER_LOCAL_STORAGE_KEYS.COHORTS.SIGN_UP),
			landingV3: getFromLocalStorage(
				USER_LOCAL_STORAGE_KEYS.COHORTS.LANDING_V3,
			),
		},
		isLoggedIn: isUserLoggedIn(),
		userPhoneNumber: getUserPhoneNumber(),
	}
}

export const clearAffiliateCode = () =>
	removeFromLocalStorage(USER_LOCAL_STORAGE_KEYS.AFFILIATE_CODE)

export const assertUserIdPresence = (userId: UserId) => {
	invariant(!isUndefinedOrNull(userId), "UserID is not present")
}

export const assertUserIsLoggedIn = (userAuthToken: UserAuthToken) =>
	invariant(!isUndefinedOrNull(userAuthToken), "User is not logged in")

export const getIsUserIdOdd = (): boolean => {
	return getUserId()! % 2 !== 0
}

export const formatPhoneNumber = (
	length: number,
	phoneNumber: string,
): string => {
	// phone number starts with 91
	if (length === 12 && phoneNumber.substring(0, 2) === "91") {
		return phoneNumber.slice(-10)
	}
	// phone number starts with 0
	if (length === 11 && phoneNumber.substring(0, 1) === "0") {
		return phoneNumber.slice(-10)
	}

	return phoneNumber
}

export const verifyPhoneNumber = (value: string) =>
	/^[6-9][0-9]{9}$/.test(value)

export const verifyEmail = (value: string) =>
	/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(value)

export const verifyName = (value: string) => /^[A-Za-z ]{2,50}$/.test(value)
