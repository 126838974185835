import { getImagesCdnUrl } from "@common/utils/env"

export const DASHBOARD_PRODUCT_NAME_KEYS = {
	bonds: "bonds",
	sgbs: "sgbs",
	fds: "fixed-deposits",
}

export const DASHBOARD_PAGE_URL_SLUG = "portfolio"
export const DASHBOARD_PAGE_URL = `/${DASHBOARD_PAGE_URL_SLUG}/`
export const DASHBOARD_PAST_INVESTMENTS_URL_SLUG = "past-investments"
const PAST_ASSETS_PAGE_URL = `${DASHBOARD_PAGE_URL}${DASHBOARD_PAST_INVESTMENTS_URL_SLUG}/`

export const SITE_URLS = {
	REFERRAL: "/referral",
	CAMPAIGN: "/campaign",
	PROFILE: "/profile/",
	BONDS: "/bonds/",
	BONDS_REFERRAL: "/app/rewards/",
	BONDS_REFERRAL_INVITE: "/referral/bonds/invite/",
	BONDS_HOME: "/bonds/",
	OLD_BONDS_HOME: "/app/home/",
	BONDS_LISTING: "/bonds/listing/",
	BONDS_KYC: "/bonds/sebi-kyc/",
	BONDS_MIGRATED_KYC: "/bonds/kyc/",
	BONDS_NOMINEE: "/bonds/nominee/",
	BONDS_CHANGE_BANK: "/bonds/kyc/change-bank/",
	BONDS_ORDERS: "/orders/",
	OLD_DASHBOARD: "/app/dashboard/",
	DASHBOARD_V3: `${DASHBOARD_PAGE_URL}${DASHBOARD_PRODUCT_NAME_KEYS.bonds}/`,
	BONDS_DASHBOARD: `${DASHBOARD_PAGE_URL}${DASHBOARD_PRODUCT_NAME_KEYS.bonds}/`,
	FD_DASHBOARD: `${DASHBOARD_PAGE_URL}${DASHBOARD_PRODUCT_NAME_KEYS.fds}/`,
	SGB_DASHBOARD: `${DASHBOARD_PAGE_URL}${DASHBOARD_PRODUCT_NAME_KEYS.sgbs}/`,
	PAST_ASSETS_BONDS: `${PAST_ASSETS_PAGE_URL}${DASHBOARD_PRODUCT_NAME_KEYS.bonds}/`,
	PAST_ASSETS_FD: `${PAST_ASSETS_PAGE_URL}${DASHBOARD_PRODUCT_NAME_KEYS.fds}/`,
	PAST_ASSETS_SGB: `${PAST_ASSETS_PAGE_URL}${DASHBOARD_PRODUCT_NAME_KEYS.sgbs}/`,
	BONDS_RFQ_CHECKOUT: "/rfq/checkout/",
	ORDERS: "/orders/",
	FD_REFERRAL: "/app/fdWaitlist/",
	FD_HOME: "/fixed-deposit/",
	FD_SAVINGS_ACCOUNT: "/fixed-deposit/unity-fund-transfer/",
	LOGOUT: "/?feedback=true&logout=true",
	BONDS_REFERRAL_HOME: "/referral/bonds/",
	BONDS_REFERRAL_DASHBOARD: "/referral/bonds/dashboard/",
	BONDS_REFERRAL_DASHBOARD_STATEMENT: "/referral/bonds/dashboard/statement",
	BONDS_REFERRAL_TC: "/referral/bonds/terms-conditions/",
	OLD_BONDS_LISTING: "/app/assets/",
	NEW_BONDS_DETAILS: "/bonds/listing/[a-zA-Z0-9]*",
	RFQ_BASE_ROUTE: "/rfq/[a-zA-Z0-9]*",
	ASSET_DETAILS_V4: "/app/asset-details-page-v4/",
	FD_LISTING: "/fixed-deposit/listing/",
	BONDS_DASHBOARD_V3: "/portfolio/bonds/",
	HELP: "/help/",
	PAYMENT_STATUS: "/payment-status/",
}

export const DASHBOARD_URLS = [
	SITE_URLS.BONDS_DASHBOARD,
	SITE_URLS.FD_DASHBOARD,
	SITE_URLS.SGB_DASHBOARD,
	SITE_URLS.PAST_ASSETS_BONDS,
	SITE_URLS.PAST_ASSETS_FD,
	SITE_URLS.PAST_ASSETS_SGB,
]

export const PRIVATE_ROUTES = [
	SITE_URLS.PROFILE,
	SITE_URLS.ORDERS,
	SITE_URLS.BONDS_MIGRATED_KYC,
	SITE_URLS.BONDS_REFERRAL_INVITE,
	SITE_URLS.BONDS_REFERRAL_HOME,
	SITE_URLS.BONDS_REFERRAL_DASHBOARD,
	SITE_URLS.BONDS_REFERRAL_DASHBOARD_STATEMENT,
	SITE_URLS.BONDS_CHANGE_BANK,
	SITE_URLS.BONDS_KYC,
]

export const SSO_API_SOURCE_FOR_REPO = "root"

const influencersCdnUrl = `${getImagesCdnUrl()}/influencers/`

export const WINT_INFLUENCERS = [
	{
		name: "Lalit Keshre",
		designation: "Co-Founder, Groww",
		img: `${influencersCdnUrl}Lalit Keshre/Lalit_Keshre.jpg`,
		logo: `${influencersCdnUrl}Lalit Keshre/groww-Logo.jpeg`,
	},
	{
		name: "Vasanth Kamath",
		designation: "Founder, smallcase",
		img: `${influencersCdnUrl}Vasanth Kamath/Vasanth_Kamath.jpg`,
		logo: `${influencersCdnUrl}Vasanth Kamath/smallcase-logo-svg.svg`,
	},
	{
		name: "Nitin Gupta",
		designation: "Founder,PayU",
		img: `${influencersCdnUrl}Nitin Gupta/Nitin_Gupta.jpg`,
		logo: `${influencersCdnUrl}Nitin Gupta/payU_logo.png`,
	},
	{
		name: "Deepak Abott",
		designation: "Co-Founder, indiagold",
		img: `${influencersCdnUrl}Deepak Abbot/Deepak Abbot.jpg`,
		logo: `${influencersCdnUrl}Deepak Abbot/indiagold-logo.png`,
	},
	{
		name: "Nithin Kamath",
		designation: "Zerodha (Rainmatter)",
		img: `${influencersCdnUrl}Nithin Kamath/Nithin_Kamath.jpg`,
		logo: `${influencersCdnUrl}Nithin Kamath/Zerodha-logo.svg`,
	},
	{
		name: "Kunal Shah",
		designation: "Founder, CRED",
		img: `${influencersCdnUrl}Kunal Shah/Kunal_Shah.jpg`,
		logo: `${influencersCdnUrl}Kunal Shah/cred-logo.webp`,
	},
	{
		name: "Tanmay Bhat",
		designation: "Finance Expert and Founder, Superteam DAO",
		img: `${influencersCdnUrl}Tanmay Bhatt/Tanmay-bhatt.webp`,
	},

	{
		name: "Vaibhav Domkundwar",
		designation: "CEO, Better Capital",
		img: `${influencersCdnUrl}Vaibhav Domkundwar/Vaibhav_Domkundwar.jpeg`,
		logo: `${influencersCdnUrl}Vaibhav Domkundwar/better-capital-logo.svg`,
	},
	{
		name: "Pranjal Kamra",
		designation: "Youtube Influencer",
		img: `${influencersCdnUrl}Pranjal Kamra/pranjal_kamara.svg`,
	},

	{
		name: "Pravin Jadhav",
		designation: "Founder, Dhan",
		img: `${influencersCdnUrl}Pravin Jadhav/Pravin_Jadhav.jpg`,
		logo: `${influencersCdnUrl}Pravin Jadhav/dhan-logo.svg`,
	},

	{
		name: "Sharique",
		designation: "Youtube Influencer",
		img: `${influencersCdnUrl}Sharique/sharique.svg`,
	},

	{
		name: "Mandeep Gill",
		designation: "Youtube Influencer",
		img: `${influencersCdnUrl}Mandeep Gill/Mandeep-gill.jpeg`,
	},
	{
		name: "Rachana Ranade",
		designation: "Youtube Influencer",
		img: `${influencersCdnUrl}Rachana Ranade/rachana_ranade.jpeg`,
	},

	{
		name: "Ankur Warikoo",
		designation: "Youtube Influencer",
		img: `${influencersCdnUrl}Ankur Warikoo/Ankur Warikoo.jpg`,
	},
	{
		name: "Raj Shamani",
		designation: "Youtube Influencer",
		img: `${influencersCdnUrl}Raj Shamani/Raj-Shamani.jpeg`,
	},
]

export const WINT_RISK_TEAM = [
	{
		id: 1,
		name: "Anshul Gupta",
		title: "Co-Founder",
		experience: "9+ years",
		linkedinUrl: "https://www.linkedin.com/in/anshul-gupta-2a096811/",
		imageUrl: `${getImagesCdnUrl()}/landing-v2/risk-assesment-team/AnshulGupta.webp`,
		description:
			"IIT Roorkee Alumnus and CFA with experience of structuring debt products worth more than 15000Cr for institutional and retail investors.",
	},
	{
		id: 2,
		name: "Samarth Tandon",
		title: "Origination Team",
		experience: "11+ years",
		linkedinUrl: "https://www.linkedin.com/in/samarthtandon/",
		imageUrl: `${getImagesCdnUrl()}/landing-v2/risk-assesment-team/SamarthTandon.webp`,
		description:
			"Worked with more than 50 institutions for their Debt raise post MBA. Previously worked with Northern Arc, Unitus Capital, Nomura and Darashaw.",
	},

	{
		id: 3,
		name: "Piyush Mohta",
		title: "Risk Team",
		experience: "10+ years",
		linkedinUrl: "https://www.linkedin.com/in/piyush-mohta-05662414/",
		imageUrl: `${getImagesCdnUrl()}/landing-v2/risk-assesment-team/PiyushMohta.webp`,
		description:
			"CA with experience in startup lending, underwritten a loan book of 2500+ Cr. Previously worked with TATA Capital, Kotak Mahindra Bank.",
	},

	{
		id: 4,
		name: "Nishant Prasad",
		title: "Compliance Team",
		experience: "7+ years",
		linkedinUrl: "https://www.linkedin.com/in/nishant-prasad-21386132/",
		imageUrl: `${getImagesCdnUrl()}/landing-v2/risk-assesment-team/NishantPrasad.webp`,
		description:
			"Lawyer with experience working across compliance in alternative investments, private equity transactions. Previously worked with Khaitan & Co and Scripbox.",
	},
	{
		id: 5,
		name: "Vaibhav Khandelwal",
		title: "Risk Team",
		experience: "4+ years",
		linkedinUrl: "https://www.linkedin.com/in/vaibhavkhandelwal13/",
		imageUrl: `${getImagesCdnUrl()}/landing-v2/risk-assesment-team/VaibhavKhandel.webp`,
		description:
			"CA with experience in credit function and has managed a portfolio of over 1000+ Cr. Previously worked with Northern Arc Capital and AU Small Finance Bank.",
	},
]

export const D3_TIME_RANGES = [
	{
		key: "1",
		label: "Overall",
		value: "totalRepaymentAllTime",
	},
	{
		key: "2",
		label: "This month",
		value: "totalRepaymentThisMonth",
	},
	{
		key: "3",
		label: "Previous month",
		value: "totalRepaymentPreviousMonth",
	},
	{
		key: "4",
		label: "Next month",
		value: "totalRepaymentNextMonth",
	},
	{
		key: "5",
		label: "This financial year",
		value: "totalRepaymentThisFinancialYear",
	},
]
