import Cookies from "js-cookie"
import { SCROLL_POSITIONS_STORAGE_KEY } from "@common/constants/storage_keys"
import { ScrollPositionsSession } from "@common/types/storage"
import { invariant, isEmpty, isString } from "../variable"

export const isBrowser = (): boolean => typeof window !== "undefined"

export const assertBrowserEnv = () => {
	invariant(isBrowser(), "Must run in browser only")
}

export const isSafariBrowser = (): boolean | null => {
	assertBrowserEnv()

	return (
		navigator.vendor.match(/apple/i) &&
		!navigator.userAgent.match(/crios/i) &&
		!navigator.userAgent.match(/fxios/i) &&
		!navigator.userAgent.match(/Opera|OPT\//)
	)
}

export const getUrlParam = (query: string): string => {
	assertBrowserEnv()

	const urlParams = new URLSearchParams(window.location.search)
	return urlParams.get(query) as string
}

export const getAllUrlParams = (): Record<string, string> => {
	assertBrowserEnv()

	const urlSearchParams = new URLSearchParams(window.location.search)
	const params = Object.fromEntries(urlSearchParams.entries())
	return params
}

export const getFromLocalStorage = <T>(key: string): T => {
	assertBrowserEnv()

	let item = null

	item = localStorage.getItem(key)

	try {
		item = !isEmpty(item) ? JSON.parse(item as string) : null

		return item as T
	} catch (error) {
		// If the item is a string, return it as is

		return item as T
	}
}

export const setToLocalStorage = (
	storedItemKey: string,
	storedItemValue: any,
): void => {
	assertBrowserEnv()

	localStorage.setItem(
		storedItemKey,
		isString(storedItemValue)
			? storedItemValue
			: JSON.stringify(storedItemValue),
	)
}

export const removeFromLocalStorage = (key: string): void => {
	assertBrowserEnv()

	localStorage.removeItem(key)
}

export const getFromSessionStorage = <T>(key: string): T => {
	assertBrowserEnv()

	let item = null

	item = sessionStorage.getItem(key)
	item = !isEmpty(item) ? JSON.parse(item as string) : null

	return item as T
}

export const setToSessionStorage = (
	storedItemKey: string,
	storedItemValue: any,
): void => {
	try {
		assertBrowserEnv()

		sessionStorage.setItem(storedItemKey, JSON.stringify(storedItemValue))
	} catch (error) {
		console.error(error)
	}
}

export const removeFromSessionStorage = (key: string): void => {
	assertBrowserEnv()

	sessionStorage.removeItem(key)
}

export const setCookie = (
	cookieName: string,
	cookieValue: string,
	cookieOptions?: object,
): void => {
	assertBrowserEnv()

	Cookies.set(cookieName, cookieValue, cookieOptions)
}

export const removeCookie = (
	cookieName: string,
	cookieOptions?: object,
): void => {
	assertBrowserEnv()

	Cookies.remove(cookieName, cookieOptions)
}

export const isWebview = (): boolean => {
	assertBrowserEnv()

	return (
		window.navigator.userAgent.includes("wint-android") ||
		window.navigator.userAgent.includes("Dalvik")
	)
}

export const isIOSWebview = (): boolean => {
	assertBrowserEnv()

	return window.navigator.userAgent.includes("wint-ios")
}

export const getIsIOSDeviceByUserAgent = (userAgent: string): boolean => {
	return /iPad|iPhone|iPod/.test(userAgent)
}

export const saveScrollPositionInSession = (pathname: string) => {
	assertBrowserEnv()

	const scrollPosition = window.scrollY.toString()
	const scrollPositions = getFromSessionStorage<ScrollPositionsSession>(
		SCROLL_POSITIONS_STORAGE_KEY,
	)

	let updatedScrollPositions = [{ pathname, scrollPosition }]

	if (updatedScrollPositions?.length > 0) {
		const filteredPositions = scrollPositions?.filter(
			(item: any) => item.pathname !== pathname,
		)

		if (filteredPositions?.length > 0) {
			updatedScrollPositions = [
				{ pathname, scrollPosition },
				...filteredPositions,
			]
		}
	}

	// Keep only the two most recent routes
	if (updatedScrollPositions.length > 2) {
		updatedScrollPositions.pop()
	}

	setToSessionStorage(SCROLL_POSITIONS_STORAGE_KEY, updatedScrollPositions)
}
