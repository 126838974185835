export const breakpoints = {
	mobile: {
		min: 300,
		max: 799,
	},
	tablet: {
		min: 800,
		max: 904,
	},
	tabletLandscape: {
		min: 905,
		max: 1239,
	},
	desktop: {
		min: 1240,
		max: 1440,
	},
	largeDesktop: {
		min: 1441,
		max: 999999999,
	},
}

export default {
	fonts: {
		sizes: {
			default: "16px",
		},
	},
	breakpoints: {
		mobile: {
			min: `${breakpoints.mobile.min}px`,
			max: `${breakpoints.mobile.max}px`,
		},
		tablet: {
			min: `${breakpoints.tablet.min}px`,
			max: `${breakpoints.tablet.max}px`,
		},
		tabletLandscape: {
			min: `${breakpoints.tabletLandscape.min}px`,
			max: `${breakpoints.tabletLandscape.max}px`,
		},
		desktop: {
			min: `${breakpoints.desktop.min}px`,
			max: `${breakpoints.desktop.max}px`,
		},
		largeDesktop: {
			min: `${breakpoints.largeDesktop.min}px`,
			max: `${breakpoints.largeDesktop.max}px`,
		},
	},
}
