import { ENVIRONMENT_KEYWORDS } from "@common/constants/keywords"

export function getEnvironmentName(): string | undefined {
	return process.env.NEXT_PUBLIC_ENVIRONMENT
}

export function getWintDomainUrl(): string | undefined {
	return process.env.NEXT_PUBLIC_WINT_WEALTH_DOMAIN_URL
}

export function getApiServerBaseUrl(): string | undefined {
	return process.env.NEXT_PUBLIC_BE_SERVER_BASE_URL
}

export const isDevEnv = (): boolean => {
	return getEnvironmentName() === ENVIRONMENT_KEYWORDS.DEV
}

export function isProdEnv(): boolean {
	return getEnvironmentName() === ENVIRONMENT_KEYWORDS.PROD
}

export function isBetaEnv(): boolean {
	return getEnvironmentName() === ENVIRONMENT_KEYWORDS.BETA
}

export const getMixpanelKey = (): string | undefined => {
	return process.env.NEXT_PUBLIC_MIXPANEL_KEY
}

export const getMixpanelProxyUrl = (): string | undefined => {
	return process.env.NEXT_PUBLIC_MIXPANEL_PROXY_URL
}

export const getCleverTapProjectId = (): string | undefined => {
	return process.env.NEXT_PUBLIC_CLEVERTAP_PROJECT_ID
}

export const getGtmId = (): string | undefined => {
	return process.env.NEXT_PUBLIC_GTM_ID
}

export const getSSOCookieTokenDomain = (): string | undefined => {
	return process.env.NEXT_PUBLIC_SSO_COOKIE_TOKEN_DOMAIN
}

export function getImagesCdnUrl(): string | undefined {
	return process.env.NEXT_PUBLIC_ASSETS_CDN_DOMAIN_URL
}
