export const PHONE_KEYWORDS = {
	PHONEPE: "PhonePe",
	PHONE_CAMPAIGN: "campaignFrom",
}

// make sure these are similar to env files
export const ENVIRONMENT_KEYWORDS = {
	PROD: "prod",
	BETA: "beta",
	DEV: "dev",
}
