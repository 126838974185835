// ! only add global keys here + split into multiple files if this is becoming big

export const USER_LOCAL_STORAGE_KEYS = {
	AUTH_TOKEN: "USER_AUTH_TOKEN",
	USER_NAME: "userName",
	USER_ID: "USER_ID",
	USER_EMAIL: "userEmail",
	USER_PHONE_NUMBER: "UP_KEY",
	KYC: {
		SHOW_KYC_DETAILS: "showKycDetails",
		KYC_STATUS: "KycStatus",
		SEBI_KYC_STATUS: "SEBI_KYC_STATUS",
	},
	HAS_SEEN_DEMOGRAPHIC_FORM: "hasSeenDemographicForm",
	IS_REGISTERED: "isRegistered",
	IS_USER_REGISTERED: "isUserRegistered",
	IS_EXISTING_INVESTOR: "isExistingInvestor",
	IS_SGB_INVESTOR: "IS_SGB_INVESTOR",
	FD_INVESTOR_STATUS: "IS_FD_INVESTOR",
	IS_FD_WAITLISTER: "IS_FD_WAITLISTER",
	BONDS_INVESTOR_STATUS: "IS_BOND_INVESTOR",
	REFERRAL_CODE: "REFERRAL_CODE",
	AFFILIATE_CODE: "AFFILIATE_CODE",
	OPT_INS: {
		WHATSAPP: "whatsAppOptIn",
	},
	PRODUCT_INTERESTS: "USER_INTEREST_INFO",
	COHORTS: {
		SIGN_UP: "SignupFlag",
		LANDING_V3: "LandingV3Flag",
	},
	USER_ATTRIBUTES_ANALYTICS: "USER_ATTRIBUTES_ANALYTICS",
}

export const USER_OBJ_COOKIE_KEY = "USER"

export const PHONEPE_LOCAL_STORAGE_KEYS = {
	CAMPAIGN: "CAMPAIGN_FROM",
}

export const PHONEPE_CAMPAIGN_LOCAL_STORAGE_KEY = "CAMPAIGN_FROM"

export const DIET_TOKEN_STORAGE_KEY = "dietToken"

export const SCROLL_POSITIONS_STORAGE_KEY = "scrollPositions"

export const ANALYTICS_KEYS_EVENTS = {
	SESSION_START: "SESSION_START",
}

export const D3_SESSION_STORAGE_KEYS = {
	RI_TOGGLE: "RI_TOGGLE",
	UPCOMING_TIME_RANGE: "UPCOMING_TIME_RANGE",
	PAID_TIME_RANGE: "PAID_TIME_RANGE",
	SP_TOGGLE: "SP_TOGGLE",
	IS_UPCOMING_REPAYMENTS_SELECTED: "IS_UPCOMING_REPAYMENTS_SELECTED",
	IS_TDS_CHECKED: "IS_TDS_CHECKED",
	IS_TDS_BANNER_SHOWN: "IS_TDS_BANNER_SHOWN",
	REPAYMENT_BREAKDOWN_SELECTED_ID: "REPAYMENT_BREAKDOWN_SELECTED_ID",
	REPAYMENT_BREAKDOWN_DATA: "REPAYMENT_BREAKDOWN_DATA",
}
