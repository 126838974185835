import { isProdEnv } from "../env"

export function isString(value: any): value is string {
	return typeof value === "string" || value instanceof String
}

export const isUndefinedOrNull = (
	variable: any,
): variable is undefined | null => {
	const checkVariable = isString(variable) ? variable.toLowerCase() : variable

	return (
		checkVariable === "null" ||
		checkVariable === "undefined" ||
		checkVariable === null ||
		checkVariable === undefined
	)
}

export const isObjectEqual = (obj1: any, obj2: any): boolean => {
	const obj1Keys = Object.keys(obj1)
	const obj2Keys = Object.keys(obj2)

	if (obj1Keys.length !== obj2Keys.length) {
		return false
	}

	return obj1Keys.every(objKey => {
		if (obj1[objKey] !== obj2[objKey]) {
			return false
		}

		return true
	})
}

export const generateRandomNumbersInRange = (
	min: number = 1,
	max: number = 3,
): number => {
	const difference: number = max - min

	let rand: number = Math.random()

	rand = Math.floor(rand * difference)

	rand += min

	return rand
}

/** ************************ lodash functions ************************* */
export function omit(
	obj: Record<string, any>,
	omitKey: string,
): Record<string, any> {
	return Object.keys(obj).reduce((result: Record<string, any>, key: string) => {
		if (omitKey.indexOf(key) === -1) {
			return { ...result, [key]: obj[key] }
		}
		return result
	}, {})
}

export function isEmpty(value: any): boolean {
	if (isUndefinedOrNull(value)) {
		return true
	}

	if (
		Array.isArray(value) ||
		typeof value === "string" ||
		typeof value.splice === "function"
	) {
		return !value.length
	}

	const keys = Object.keys(value)
	if (keys.every(key => !Object.hasOwnProperty.call(value, key))) {
		return false
	}

	return true
}

// Throw an error if the condition fails
// Strip out error messages for production
// > Not providing an inline default argument for message as the result is smaller
export function invariant(
	condition: any,
	// Can provide a string, or a function that returns a string for cases where
	// the message takes a fair amount of effort to compute
	message?: string | (() => string),
): asserts condition {
	const prefix: string = "Invariant failed"

	if (condition) {
		return
	}
	// Condition not passed

	// In production we strip the message but still throw
	if (isProdEnv()) {
		throw new Error(prefix)
	}

	// When not in production we allow the message to pass through
	// *This block will be removed in production builds*

	const provided: string | undefined =
		typeof message === "function" ? message() : message

	// Options:
	// 1. message provided: `${prefix}: ${provided}`
	// 2. message not provided: prefix
	const value: string = provided ? `${prefix}: ${provided}` : prefix
	throw new Error(value)
}
